import { ReactNode } from 'react';
import { PermissionGatedContent } from 'components/PermissionGatedContent';
import { PermissionsEnum } from 'model/User';
import { GatedContent } from 'components/GatedContent';
import { AppLoadingMessage } from 'components/AppLoadingMessage';
import useUserV2 from 'hooks/useUserV2';
import { ErrorDisplay } from '@automata/ui';
import { ActiveFlag } from 'hooks/unleashHooks';

interface Props {
  permissionsNeeded: PermissionsEnum;
  children: ReactNode;
  unleashFlag?: ActiveFlag;
}

export const GrantAccess = ({
  children,
  unleashFlag,
  permissionsNeeded,
}: Props): JSX.Element => {
  const { data, error, isLoading } = useUserV2();

  if (isLoading) {
    return <AppLoadingMessage />;
  }

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        userMessage="Failed to load your profile, please try again"
        logToConsole
      />
    );
  }

  return (
    <PermissionGatedContent
      permissionNeeded={permissionsNeeded}
      userPermissions={data.permissions}
    >
      <>
        {unleashFlag !== undefined ? (
          <GatedContent unleashFlag={unleashFlag}>{children}</GatedContent>
        ) : (
          children
        )}
      </>
    </PermissionGatedContent>
  );
};
